import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider, Text } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { StaticRouter } from "react-router-dom/server";




const Render = ({url}) => {
  if(typeof window == 'undefined'){
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS >
        <StaticRouter location={url}>
          <Notifications />
          <App />
        </StaticRouter>
    </MantineProvider>
    )
  }
  else{
    return (
    <MantineProvider withGlobalStyles withNormalizeCSS >

      <BrowserRouter>
        <Notifications />
        <App />
      </BrowserRouter>
  </MantineProvider>
    )
  }

 

}


export default Render